import { defineStore } from 'pinia';

const blogStore = defineStore('blog', {
  state: () => ({
    blog_id: null,
    section_id: null,
    apps_id: null,
  }),
  

  actions: {
    setBlogId(id) {
      this.blog_id = id;
    },
    clearBlogId() {
      this.blog_id = null;
    },
    setSectionId(id) {
      this.section_id = id;
    },
    clearSectionId() {
      this.section_id = null;
    },
    setAppsId(id) {
      this.apps_id = id;
    },
    clearAppsId() {
      this.apps_id = null;
    }
  },
  


  getters: {
    getBlogId: (state) => state.blog_id,
    getSectionId: (state) => state.section_id,
    getAppsId: (state) => state.apps_id,
  },


});

export default blogStore; 