<template>
  <div class="user-center">
    <div class="user-header">
      <div class="left-space"></div>
      <div class="tab-buttons">
        <a-button 
          type="primary"
          @click="showWechatModal"
        >
          微信绑定
        </a-button>
        <a-button 
          type="primary"
          @click="showPasswordModal"
        >
          修改密码
        </a-button>
        <a-button 
          type="primary"
          @click="showZoneModal"
        >
          设置可用区
        </a-button>
      </div>
    </div>

    <div class="tab-content">
      <div>
        <a-form ref="formRef" :model="userInfo" :rules="rules" layout="horizontal"
                :label-col="{style: {width: '150px'} }" :wrapper-col="{span: 14}">
          <a-row>
            <a-col :span="12">
              <a-form-item label="用户名" name="pet_name">
                <a-input v-model:value="userInfo.pet_name" placeholder="请输入昵称"/>
                <div class="remind">
                  <img src="/hw_web/static/src/img/remind.png" height="15"/>
                  考试认证学生请填写真实姓名
                </div>
              </a-form-item>
              
              <a-form-item label="性别" name="sex">
                <a-select v-model:value="userInfo.sex" :allowClear="true" placeholder="请选择性别"
                          show-search :options="userInfo.sex_ids" :filter-option="filterOption">
                </a-select>
              </a-form-item>
              <a-form-item label="联系电话" name="phone">
                <a-input v-model:value="userInfo.phone" placeholder="请输入联系电话"/>
              </a-form-item>
              <a-form-item label="电子邮箱" name="email">
                <a-input v-model:value="userInfo.email" placeholder="请输入电子邮箱"/>
              </a-form-item>
              <a-form-item label="所属院校" name="school_id">
                <a-select v-model:value="userInfo.school_id" :allowClear="true" placeholder="请选择所属院校"
                          show-search :options="userInfo.school_ids" :filter-option="filterOption">
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="头像" name="file_list">
                <a-upload
                    v-model:file-list="userInfo.file_list"
                    :customRequest="uploadDummyRequest"
                    list-type="picture-card"
                    @preview="handlePreview"
                    :max-count="1"
                    accept=".png,.jpg,.jpeg"
                >
                  <div v-if="userInfo.file_list.length < 1">
                    <plus-outlined/>
                    <div class="ant-upload-text">上传图片</div>
                  </div>
                </a-upload>
                <a-modal :open="previewVisible" :title="previewTitle" :footer="null" @cancel="cancelPreview">
                  <img alt="example" style="width: 100%" :src="previewImage"/>
                </a-modal>
              </a-form-item>

            </a-col>
          </a-row>
          <a-row>
            <div class="section-divider">
              实名认证信息
            </div>
          </a-row>
          <a-row>
            <a-col :span="12">
              <a-form-item label="真实姓名" name="name">
                <a-input v-model:value="userInfo.name" placeholder="请输入真实姓名"/>
              </a-form-item>
              
              <a-form-item label="身份证号" name="identification">
                <a-input v-model:value="userInfo.identification" placeholder="请输入有效的18位身份证号码"/>
              </a-form-item>
            </a-col>
          </a-row>
          <a-flex justify="center" align="center">
            <a-button type="primary" @click="confirmFormUpdate">保存</a-button>
          </a-flex>
        </a-form>
      </div>
    </div>

    <!-- 微信绑定弹窗 -->
    <a-modal
      v-model:visible="wechatModalVisible"
      title="微信绑定"
      :footer="null"
      width="500px"
    >
      <template v-if="userInfo.wechat_unionid">
        <a-card title="基本信息" class="mb-4">
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item label="微信头像">
                <img :src="`/web/image/res.users/${userInfo.id}/image_512`" height="80" width="80" />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="24">
              <a-form-item label="微信昵称">
                <span>{{ userInfo.wechat_nickname }}</span>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="16">
            <a-col :span="20" :offset="4">
              <a-popconfirm
                title="确认解除绑定？"
                @confirm="unbind"
              >
                <a-button type="primary" danger ghost>解除绑定</a-button>
              </a-popconfirm>
            </a-col>
          </a-row>
        </a-card>
      </template>
      
      <template v-else>
        <h4 class="text-center mb-4">扫描下方二维码绑定微信</h4>
        <iframe :src="wechatIframeSrc" frameborder="0"></iframe>
      </template>
    </a-modal>

    <!-- 修改密码弹窗 -->
    <a-modal
      v-model:visible="passwordModalVisible"
      title="修改密码"
      @ok="changePwd"
      :width="600"
    >
      <a-form ref="formPwdRef" :model="userInfo" :rules="rules" layout="horizontal"
              :label-col="{style: {width: '120px'} }" :wrapper-col="{span: 16}">
        <a-form-item label="当前密码" name="old_pwd">
          <a-input-password v-model:value="userInfo.old_pwd" placeholder="请输入当前密码"/>
        </a-form-item>
        <a-form-item label="新密码" name="new_password">
          <a-input-password v-model:value="userInfo.new_password" placeholder="请输入新密码"/>
          <small id="passwordHelp" style="color: red;">*长度为8-16字符;至少包含一个大小写字母、数字和特殊字符</small>
        </a-form-item>
        <a-form-item label="确认新密码" name="confirm_pwd">
          <a-input-password v-model:value="userInfo.confirm_pwd" placeholder="请输入新密码"/>
        </a-form-item>
      </a-form>
    </a-modal>

    <!-- 设置可用区弹窗 -->
    <a-modal
      v-model:visible="zoneModalVisible"
      title="设置可用区"
      @ok="changeUserDefaultZone"
      :width="600"
    >
      <a-form :model="setUserDefaultZone" name="basic"
              layout="horizontal" :label-col="{style: {width: '120px'} }" :wrapper-col="{span: 16}">
        <a-form-item
            label="用户默认可用区"
            name="zone_code_selected"
            :rules="[{ required: true, message: '请选择用户默认可用区' }]"
        >
          <a-select
              v-model:value="setUserDefaultZone.zone_code_selected"
              placeholder="用户默认可用区">
            <a-select-option
                v-for="item in setUserDefaultZone.zone_code_options"
                :key="item.zone_code"
                :value="item.zone_code">
              {{ item.zone_name }}
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script setup>
import {computed, onMounted, reactive, ref} from 'vue'
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import {
  getBase64,
  newCancelPreview,
  newHandlePreview,
  newPreviewState,
  newUploadDummyRequest
} from "@/utils/file_utils";
import {logDebug, logError} from "@/utils/logger";
import {message} from "ant-design-vue";

const {previewVisible, previewImage, previewTitle} = newPreviewState();
const cancelPreview = newCancelPreview(previewVisible, previewTitle)
const handlePreview = newHandlePreview(previewImage, previewVisible, previewTitle)
const uploadDummyRequest = newUploadDummyRequest()

const formRef = ref()
const rules = {
  pet_name: [
    {
      required: true,
      message: '请输入昵称',
      trigger: 'blur',
    },
  ],
  file_list: [
    {
      required: true,
      message: '请上传头像',
      trigger: 'change',
    },
  ],
  sex: [
    {
      required: true,
      message: '请选择性别',
      trigger: 'change',
    },
  ],
  name: [
    {
      required: true,
      message: '请输入真实姓名',
      trigger: 'blur',
    },
  ],
  phone: [
    {
      required: true,
      message: '请输入联系电话',
      trigger: 'blur',
    },
    {
      pattern: /^1[3-9]\d{9}$/,
      message: '请输入有效的手机号码（11位数字）',
      trigger: 'blur',
    },
  ],
  email: [
    {
      required: true,
      message: '请输入电子邮箱',
      trigger: 'blur',
    },
    {
      type: 'email',
      message: '请输入有效的电子邮箱地址',
      trigger: ['blur', 'change'],
    },
  ],
  identification: [
    {
      pattern: /^[1-9]\d{5}(18|19|20)\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])\d{3}(\d|X|x)$/,
      message: '请输入有效的18位身份证号码',
      trigger: 'blur',
    },
  ],
  old_pwd: [
    {required: true, message: '当前密码不能为空', trigger: 'blur'},
  ],
  new_password: [
    {required: true, message: '新密码不能为空', trigger: 'blur'},
    {min: 8, max: 16, message: '新密码长度需为8-16字符', trigger: 'blur'},
    {
      pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,16}$/,
      message: '新密码至少包含一个大小写字母、数字和特殊字符',
      trigger: 'blur',
    },
  ],
  confirm_pwd: [
    {required: true, message: '确认新密码不能为空', trigger: 'blur'},
    {
      validator(_, value) {
        if (value !== userInfo.new_password) {
          return Promise.reject('确认新密码需与新密码一致');
        }
        return Promise.resolve();
      },
      trigger: 'blur',
    },
  ],
}
const userInfo = reactive({
  pet_name: '',
  file_list: [],
  sex: '',
  name: '',
  phone: '',
  email: '',
  identification: '',
  school_id: null,
  sex_ids: [],
  school_ids: [],
  old_pwd: '',
  new_password: '',
  confirm_pwd: '',
});
const filterOption = (input, option) => {
  return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
const confirmFormUpdate = () => {
  formRef.value.validate()
  .then(async () => {
    const filePromises = await Promise.all(userInfo.file_list.map(async file => {
      const base64Data = await getBase64(file.originFileObj);
      return {
        ...file,
        raw_data: base64Data,
      };
    }));
    await jsonRPC({
      url: "/vue/users/update",
      params: {
        name: userInfo.name,
        file_list: filePromises,
        pet_name: userInfo.pet_name,
        sex: userInfo.sex,
        phone: userInfo.phone,
        email: userInfo.email,
        identification: userInfo.identification,
        school_id: userInfo.school_id,
      },
      success() {
        message.success('保存成功')
      },
      fail(error) {
        logError(`保存失败, `, error)
        message.error('保存失败, 请稍后再试')
      },
    });
    return
  })
  .catch(error => {
    logError('error', error);
    return
  });
};
onMounted(() => {
  fetch1Data();
  fetch2Data();
  fetch3Data();
  fetchZoneData();
})
const wechatModalVisible = ref(false);
const passwordModalVisible = ref(false);
const zoneModalVisible = ref(false);

const showWechatModal = () => {
  wechatModalVisible.value = true;
};

const showPasswordModal = () => {
  passwordModalVisible.value = true;
};

const showZoneModal = () => {
  zoneModalVisible.value = true;
};

function fetch1Data() {
  jsonRPC({
    url: "/vue/users/setting",
    params: {},
    success(res) {
      logDebug(`查询成功`, res.data.result.data)
      Object.assign(userInfo, res.data.result.data)
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
}

function fetch2Data() {
  jsonRPC({
    url: "/vue/users/wechat",
    params: {},
    success(res) {
      logDebug(`查询成功2`, res.data.result.data)
      Object.assign(userInfo, res.data.result.data)
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
}

function fetch3Data() {
  jsonRPC({
    url: "/vue/users/changePassword",
    params: {},
    success(res) {
      logDebug(`查询成功3`, res.data.result.data)
    },
    fail(error) {
      logError(`查询失败, `, error)
    },
  });
}

const unbind = () => {
  jsonRPC({
    url: "/vue/users/unbind/wechat",
    params: {},
    success() {
      message.success('解除绑定成功');
      wechatModalVisible.value = false;
      fetch1Data()
    },
    fail(error) {
      logError(`解除绑定失败, `, error)
      message.error('解除绑定失败');
    },
  });
}
const formPwdRef = ref()
const changePwd = () => {
  formPwdRef.value.validate()
      .then(() => {
        jsonRPC({
          url: "/vue/users/changePassword",
          params: {
            login: userInfo.login,
            password: userInfo.old_pwd,
            new_password: userInfo.new_password,
            confirm_pwd: userInfo.confirm_pwd,
          },
          success(res) {
            logDebug(`保存成功`, res)
            message.success('密码修改成功');
            passwordModalVisible.value = false;
            userInfo.old_pwd = '';
            userInfo.new_password = '';
            userInfo.confirm_pwd = '';
          },
          fail(error) {
            logError(`保存失败, `, error)
            message.error('密码修改失败');
          },
        });
        return
      })
      .catch(error => {
        logDebug('error', error);
        return
      });
}

const setUserDefaultZone = reactive({
  zone_code_selected: "",
  zone_code_options: [
  ],
})

function fetchZoneData() {
  jsonRPC({
    url: "/vue/console/zone",
    params: {},
    success(res) {
      const data = getResponseData(res)
      logDebug(`获取可用区信息成功`, data)
      setUserDefaultZone.zone_code_options = data.zone_list
      setUserDefaultZone.zone_code_selected = data.user_default_zone_code
    },
    fail(error) {
      logError(`获取可用区信息失败, `, error);
    },
  });
}

const changeUserDefaultZone = () => {
  jsonRPC({
    url: "/vue/console/zone/update",
    params: {
      zone_code: setUserDefaultZone.zone_code_selected
    },
    success(res) {
      const data = getResponseData(res)
      logDebug(`设置用户默认可用区成功`, data)
      message.info(`设置用户默认可用区成功`, 3)
      zoneModalVisible.value = false;
    },
    fail(error) {
      logError(`设置用户默认可用区失败, `, error);
      message.error(`设置用户默认可用区失败`, 3)
    },
  }).then(function () {
    fetchZoneData()
  });
}
const wechatIframeSrc = computed(() => {
  return `https://open.weixin.qq.com/connect/qrconnect?appid=${userInfo.wechat_login_appid}&styletype=
            &scope=snsapi_login&redirect_uri=${userInfo.wechat_login_redirect_uri}&sizetype=
            &state=${userInfo.wechat_login_state}&bgcolor=
            &login_type=jssdk
            &fast_login=0
            &self_redirect=false
            &style=${userInfo.wechat_login_style}&rst=
            &href=${userInfo.wechat_login_href}`;
  // 参考：https://developers.weixin.qq.com/doc/oplatform/Website_App/WeChat_Login/Wechat_Login.html
});
</script>

<style scoped lang="scss">
.user-center {
  height: 100%;
  width: 100%;
  padding: 0 16px;
  overflow-y: auto
}

.user-center .remind {
  margin-top: 5px;
  font-size: 12px;
  color: #999;
}

.avatar-100 {
  border-radius: 50%;
}

iframe {
  display: inherit;
  width: 235px;
  height: 270px;
  margin: 0 auto;
}

.user-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-top: 20px;
  
  .left-space {
    flex: 1;
  }
  
  .tab-buttons {
    display: flex;
    gap: 8px;
    
    .ant-btn {
      border-radius: 20px;
      min-width: 100px;
      
      &.ant-btn-primary {
        background-color: #1890ff;
        
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}

.tab-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 24px;

  .section-divider {
    margin: 24px 0;
    padding-bottom: 12px;
    font-weight: bold;
    text-align: left;
    padding-left: 150px;
    border-bottom: 1px solid #f0f0f0;
    color: #333;
    font-size: 16px;
  }
}
</style>
