<template>
  <div class="container training_detail">
    <a-breadcrumb separator=">">
      <a-breadcrumb-item>
        <router-link to="/training">证书培训</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>{{ data_list.training_name }}</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="info p-4 mb-3">
      <div class="row mx-0 title">
        <div class="col-md-4 pl-0">
          <div class="img_box">
            <img :src="`/web/image/hw.certification.template/${training_id}/image_512?t=${new Date().getTime()}`"/>
          </div>
        </div>
        <div class="col-md-8 px-0">
          <h5>{{ data_list.training_name }}</h5>
          <div class="certificate">{{ data_list.summary }}</div>
          <div v-if="data_list.training_type === '0'">
            <div class="course">
              必修课程：
              <span v-for="course in data_list.course_ids" :key="course.id">
                {{course.name}}
              </span>
            </div>
            <div>课时数：{{ data_list.period_num }}</div>
          </div>
          <div v-if="data_list.student_ids.includes(data_list.user)">
            <div class="d-flex" v-if="data_list.exam_page_id && data_list.is_progress">
              <div v-if="!data_list.cert">
                <div v-if="data_list.is_notice && (!data_list.confirmation_notice || !data_list.confirmation_notice.is_confirm)">
                  <a @click.prevent="redirectNotice" class="btn join">
                    开始考试
                  </a>
                </div>
                <a v-else-if="data_list.can_exam" @click="onExam" class="btn join">开始考试</a>
                <a v-else class="btn join disabled">开始考试</a>
              </div>
              <div v-else-if="data_list.cert.state === '1'" class="mr-4" style="height: 40px">
                <h5 class="text-success mb-0">
                  考试通过！
                </h5>
              </div>
              <div v-else-if="data_list.cert.frequency === 0" class="mr-4" style="height: 40px">
                <h5 class="mb-0" style="color: #ff8857; line-height: 40px">
                  考试未通过！考试次数已用完。
                </h5>
              </div>
              <div v-else class="d-flex align-items-center">
                <div v-if="data_list.is_notice && (!data_list.confirmation_notice || !data_list.confirmation_notice.is_confirm)">
                  <a @click.prevent="redirectNotice" class="btn join">
                    开始考试
                  </a>
                </div>
                <a v-else-if="data_list.can_exam" @click="onExam" class="btn join">开始考试</a>
                <a v-else class="btn join disabled">开始考试</a>
                <button class="btn btn-primary ml-3" @click="open = true">
                  上传作品
                </button>
                <a-modal v-model:open="open" title="上传作品" :confirm-loading="confirmLoading" @ok="handleOk">
                  <a-upload
                      v-model:file-list="file_list"
                      :customRequest="uploadDummyRequest"
                      @preview="handlePreview"
                      :max-count="1"
                  >
                    <a-button>
                      <UploadOutlined style="display: inline-flex;"/>
                      上传
                    </a-button>
                  </a-upload>
                </a-modal>
                <a v-if="data_list.cert.student_file" :href="`/web/image/hw.certification/${data_list.cert.id}/student_file`"
                  target="_blank">
                  {{ data_list.user_name }} 上传的作品
                </a>
                <span v-else>暂无上传作品</span>
              </div>
            </div>
            <a v-else class="btn" @click="activeKey = '3'">继续学习</a>
          </div>
          <a v-else @click="onJoin" class="btn join">加入培训</a>
        </div>
      </div>
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="证书介绍">
          <div v-html="data_list.description"></div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="学员须知">
          <template v-if="data_list.is_notice">
            <div v-html="data_list.notice"></div>
            <a-button v-if="!data_list.confirmation_notice || !data_list.confirmation_notice.is_confirm" 
              @click="confirmNotice" type="primary" size="Large">确认须知</a-button>
          </template>
        </a-tab-pane>
        <template v-if="data_list.user_id!='4'">
          <a-tab-pane key="3" tab="培训课程" v-if="data_list.training_type=='0'">
            <div id="course">
              <div v-for="course in data_list.course_ids" :key="course.id" class="row mx-0">
                <div class="col-md-3 pl-0">
                  <div class="img_box">
                    <img v-if="course.image_512" :src="`/web/image/hw.course/${course.id}/image_512`" />
                    <img v-else src="/hw_web/static/src/img/course_default.png" />
                  </div>
                </div>
                <div class="col-md-9 px-0">
                  <h5>{{ course.name }}</h5>
                  <div v-for="(row,index) in data_list.pass_study_time" :key="index">
                    <div v-if="course.id === row.course_id">
                      <!-- 总课时: {{ row.course_time }}小时 -->
                      总小节: {{ row.course_lesson_nums }}节
                    </div>
                  </div>
                  <div>
                    <router-link :to="`/course/${course.id}`" class="btn join">继续学习</router-link>
                    <a v-if="course.environment_id" class="btn" target="_blank" :href="`/experiment/environment/exec/${course.environment_id}`">
                      在线环境
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="4" tab="练习刷题" v-if="data_list.exercises.length>0">
            <h5>练习刷题</h5>
            <component :is="currentComponent" @call-parent-method="parentMethod" @call-parent-refresh="parentRefresh"
              :list=data_list.exercises :exercise_id=exercise_id></component>
          </a-tab-pane>
          <a-tab-pane key="5" tab="我的进度" v-if="data_list.training_type=='0' || data_list.exercises.length>0">
            <div id="rate">
              <template v-if="data_list.training_type=='0'">
                <h5>课程进度</h5>
                <div class="row mx-0">
                  <div v-for="course in data_list.course_ids" :key="course.id" class="col-md-6 row mx-0">
                    <div class="col-4 pl-0">
                      <div class="img_box">
                        <img v-if="course.image_512" :src="`/web/image/hw.course/${course.id}/image_512`"/>
                        <img v-else src="/hw_web/static/src/img/course_default.png" />
                      </div>
                    </div>
                    <div class="col-8 px-0">
                      <p>{{ course.name }}</p>
                      <div v-if="calculateProgress(course.id)">
                        <div>视频进度</div>
                        <a-progress :percent="calculateProgress(course.id).videoProgress" status="active" stroke-color="#1890ff" />
                        <div>
                          {{ calculateProgress(course.id).lessonWatched }}/{{ calculateProgress(course.id).totalLessons }}节
                        </div>
                      </div>
                      <div v-if="calculateProgress(course.id)">
                        <div>练习进度</div>
                        <a-progress :percent="calculateProgress(course.id).exerciseProgress" status="active" stroke-color="#ff4d4f" />
                        <div>
                          {{ calculateProgress(course.id).exercisePassed }}/{{ calculateProgress(course.id).totalExercises }}个
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-if="data_list.exercises.length>0">
                <h5>练习刷题</h5>
                <div class="row mx-0">
                  <div v-for="row in data_list.exercises" :key="row.exercise" class="col-md-6">
                    {{ row.name }}<br/>
                    <span class="mr-3">刷题次数：{{ row.num }}次</span>
                    <span>最高得分：{{ Math.round(row.fraction) }}分</span>
                  </div>
                </div>
              </template>
            </div>
          </a-tab-pane>
        </template>
      </a-tabs>
    </div>
  </div>
</template>

<script setup>
import { logDebug, logError } from "@/utils/logger";
import { message, Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { onMounted, ref, reactive, createVNode, computed } from "vue";
import { jsonRPC, getResponseData } from "@/utils/http_utils";
import { getRouterParam, getRouterQuery } from "@/utils/router_utils";
import { useRouter } from "vue-router";
const router = useRouter();
const training_id = ref(getRouterParam(router, "id"));
const is_activeKey = ref(getRouterQuery(router, "activeKey"));
const activeKey = ref('1');
if (is_activeKey.value) {
  activeKey.value = is_activeKey.value
}

import {UploadOutlined} from '@ant-design/icons-vue';
import {getBase64,newHandlePreview,newPreviewState,newUploadDummyRequest} from "@/utils/file_utils";
const {previewVisible, previewImage, previewTitle} = newPreviewState();
const handlePreview = newHandlePreview(previewImage, previewVisible, previewTitle)
const uploadDummyRequest = newUploadDummyRequest()
const open = ref(false);
const confirmLoading = ref(false);
const handleOk = async () => {
  confirmLoading.value = true;
  const filePromises = await Promise.all(file_list.value.map(async file => {
    const base64Data = await getBase64(file.originFileObj);
    return {
      ...file,
      raw_data: base64Data,
    };
  }));
  await jsonRPC({
    url: "/api/training/upload_file",
    params: {
      id: training_id.value,
      file_list: filePromises,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`上传文件成功`, data);
      message.success('上传文件成功')
      fetchData();
    },
    fail(error) {
      logError(`上传文件请求失败：`, error)
      message.error(`上传文件请求失败，请稍后再试`);
    },
  }).then(()=>{
    open.value = false;
    confirmLoading.value = false;
  });
};
const file_list = ref()
const data_list = reactive({
  training_name: "",
  user: 0,
  student_ids: [],
  exercises: [],
  can_exam: true,
});

function fetchData() {
  jsonRPC({
    url: "/api/training/detail",
    params: {
      id: training_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取证书培训详情成功`, data);
      Object.assign(data_list,data)
    },
    fail(error) {
      logError(`获取证书培训详情失败`, error);
      message.error(`获取证书培训详情失败，请稍后再试`);
    },
  });
}
onMounted(() => {
  fetchData();
});
// 加入培训
const onJoin = ()=>{
  jsonRPC({
    url: "/api/training/join",
    params: {
      id: training_id.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`加入培训成功`, data);
      message.success(`加入培训成功`);
      fetchData();
    },
    fail(error) {
      logError(`加入培训失败`, error);
      message.error(`加入培训失败，请稍后再试`);
    },
  });
}
// 转到阅读学员须知
function redirectNotice() {
  Modal.confirm({
    title: '请先阅读学员须知！',
    icon: createVNode(ExclamationCircleOutlined),
    onOk() {
      activeKey.value = '2'
    },
  });
}
// 确认阅读学员须知
function confirmNotice() {
  Modal.confirm({
    title: '是否已阅读学员须知？',
    icon: createVNode(ExclamationCircleOutlined),
    onOk() {
      jsonRPC({
        url: "/api/training/notice",
        params: {
          id: training_id.value,
        },
        success(res) {
          const data = getResponseData(res);
          logDebug(`已阅读学员须知`, data);
          message.success(`已阅读学员须知`);
          fetchData();
        },
        fail(error) {
          logError(`阅读学员须知`, error);
          message.error(`阅读学员须知失败，请稍后再试`);
        },
      });
    },
  });
}
// 开始考试
const onExam = ()=>{
  router.push(`/training/${training_id.value}/${data_list.exam_page_id.id}`)
}
// 计算进度的方法
const calculateProgress = (courseId) => {
  const row = data_list.pass_study_time.find(item => item.course_id === courseId);
  if (!row) return null;

  return {
    videoProgress: row.video_progress,
    lessonWatched: row.lesson_watched_num,
    totalLessons: row.course_lesson_nums,
    exerciseProgress: row.exercise_paper_progress,
    exercisePassed: row.exercise_paper_pass_count,
    totalExercises: row.exercise_paper
  };
};
// 组件切换
import exerciseDetail from './exerciseDetail.vue'
import exerciseList from './exerciseList.vue'
const selectedKey = ref('list');
const exercise_id = ref();
const components = {
  detail: exerciseDetail,
  list: exerciseList,
}
const currentComponent = computed(() => {
  return components[selectedKey.value]
})
function parentMethod(e) {
  selectedKey.value = e.component;
  exercise_id.value = e.exercise_id;
}
function parentRefresh() {
  fetchData();
}
</script>

<style scoped lang="scss">
.training_detail {
  margin-bottom: 30px;
  .info {
    background-color: #fff;
    > .title {
      margin-bottom: 1rem;
      > .col-md-4 {
        > .img_box {
          padding-bottom: 62.5%;
        }
      }
      > .col-md-8 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > h5 {
          font-size: 18px;
          font-weight: bold;
          margin: 0;
        }
        > .certificate {
          color: #57a6ff;
        }
        .course {
          > span {
            color: #57a6ff;
            margin-right: 7px;
            white-space: nowrap;
          }
        }
        .btn {
          padding-top: 0;
          padding-bottom: 0;
          width: 100px;
          line-height: 38px;
          text-align: center;
          color: #fff;
          background-color: #57a6ff;
          border-color: #fff !important;
          font-size: 16px;
          &.join {
            background-color: #ffa257;
          }
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }
    #course {
      > div {
        padding: 15px 0;
        &:not(:last-child) {
          border-bottom: 1px solid #dee2e6;
        }
        > .col-md-9 {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          > h5 {
            font-size: 16px;
            font-weight: bold;
            margin: 0;
          }
          > .progress {
            height: 10px;
            > .progress-bar {
              background-color: #3291f8;
            }
          }
          > div:last-child {
            font-size: 0;
            > .btn {
              padding-top: 0;
              padding-bottom: 0;
              width: 100px;
              line-height: 38px;
              text-align: center;
              color: #fff;
              background-color: #57a6ff;
              margin-right: 10px;
              font-size: 16px;
              &.join {
                background-color: #ffa257;
              }
              &:hover {
                opacity: 0.8;
              }
              &.disabled {
                background-color: #ccc;
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }
    #exercise {
      > h5 {
        font-size: 16px;
        margin-top: 15px;
        margin-bottom: 0;
      }
      > .exercise_root {
        > .exercise_job {
          > table {
            > tbody {
              > tr > td {
                border-color: #dcedff;
                > a {
                  color: #49a9ff;
                  cursor: pointer;
                }
              }
              > tr:first-child {
                background-color: #e6f3ff;
              }
            }
          }
        }
      }
    }
    #rate {
      > h5 {
        font-size: 16px;
        margin-bottom: 15px;
      }
      > .row {
        > .col-md-6 {
          margin-bottom: 15px;
          > .col-8 {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            > p {
              margin-bottom: 0;
            }
            > div {
              display: flex;
              align-items: center;
              justify-content: space-between;
              > div {
                flex-shrink: 0;
                &.ant-progress-line{
                  flex-shrink: 1;
                  margin: 0;
                  margin-left: 5px;
                }
              }
            }
          }
        }
      }
      > .col {
        margin-top: 15px;
        > a {
          font-size: 16px;
          color: #0078ff;
          margin-right: 20px;
        }
        > span {
          margin-right: 20px;
        }
      }
    }
  }
}
</style>
