<template>
  <div class="service">
    <div>
      <div class="service-tabs">
        <div class="tab-list">
          <div
              :class="{ active: activeTab === 'service' }"
              class="tab-item"
              @click="activeTab = 'service'"
          >
            网络服务
          </div>
          <div :class="{ active: activeTab === 'network' }"
               class="tab-item"
               style="display: none"
               @click="activeTab = 'network'"
          >
            网关信息
          </div>
        </div>
        <a-button
            v-if="activeTab === 'service'"
            type="primary"
            @click="handlePpenAddServiceModal"
        >
          添加服务
        </a-button>
      </div>

      <div v-if="activeTab === 'service'">
        <a-modal v-model:open="openAddServiceModal" title="添加服务" @ok="handlePpenAddServiceModalOk">
          <a-form
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
              layout="horizontal">
            <a-form-item :rules="[{ required: true, message: '请填写服务类型' }]" label="服务类型">
              <a-select v-model:value="createServiceForm.protocol">
                <a-select-option value="HTTP">HTTP</a-select-option>
                <a-select-option value="TCP-LB">TCP</a-select-option>
                <a-select-option value="UDP-LB">UDP</a-select-option>
                <!--              <a-select-option value="TCP">TCP(NodePort)</a-select-option>-->
                <!--              <a-select-option value="UDP">UDP(NodePort)</a-select-option>-->
              </a-select>
            </a-form-item>
            <a-form-item :rules="[{ required: true, message: '请填写内部端口' }]" label="内部端口">
              <a-input-number v-model:value="createServiceForm.servicePort"/>
            </a-form-item>
          </a-form>
        </a-modal>
        <a-table :columns="serviceColumns" :data-source="serviceData"
                 :locale="{emptyText: '暂无数据'}" :pagination="false" class="service-table">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'action'">
              <a-popconfirm cancel-text="否" ok-text="是" title="确认删除？"
                            @confirm="()=>deleteService(record)">
                <a-button type="link">
                  删除
                </a-button>
              </a-popconfirm>
            </template>
            <template v-if="column.key === 'adjust_access_url'">
              <a v-if="record['service_type'] === 'HTTP服务'"
                 :href="record['adjust_access_url']" target="_blank">
                {{ record['adjust_access_url'] }}
              </a>
              <span v-else>{{ record['adjust_access_url'] }}</span>
            </template>
          </template>
        </a-table>
        <p class="service-tip">TCP服务和UDP服务请使用专用工具访问。</p>
      </div>

      <div v-else-if="activeTab === 'network'">
        <div class="network-content">
          <div class="network-info">
            <div class="info-row">
              <div class="info-item">
                <span class="label">IPv4 地址:</span>
                <span class="value">{{ networkInfo.ipv4Address || '192.160.1.1' }}</span>
              </div>
              <div class="info-item">
                <span class="label">IPv6 地址:</span>
                <span class="value">{{ networkInfo.ipv6Address || '未开通' }}</span>
              </div>
            </div>

            <div class="info-row">
              <div class="info-item">
                <span class="label">IPv4 默认网关:</span>
                <span class="value">{{ networkInfo.ipv4Gateway || '192.160.1.1' }}</span>
              </div>
              <div class="info-item">
                <span class="label">IPv6 默认网关:</span>
                <span class="value">{{ networkInfo.ipv6Gateway || '未开通' }}</span>
              </div>
            </div>

            <div class="info-row">
              <div class="info-item">
                <span class="label">IPv4 DNS服务器:</span>
                <div class="value">
                  <div>{{ networkInfo.ipv4Dns1 || '114.114.114.114 (未加密)' }}</div>
                  <div>{{ networkInfo.ipv4Dns2 || '8.8.8.8 (未加密)' }}</div>
                </div>
              </div>
              <div class="info-item">
                <span class="label">IPv6 DNS服务器:</span>
                <span class="value">{{ networkInfo.ipv6Dns || '未开通' }}</span>
              </div>
            </div>

            <div class="info-row">
              <div class="info-item">
                <span class="label">云端互联:</span>
                <span class="value">
                  <a class="cloud-link" @click="goToCloudLink">
                    <img alt="wifi" class="wifi-icon" src="@/assets/icon/cloud2.png"/>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, reactive, ref} from 'vue';
import {logDebug, logError} from "@/utils/logger";
import {getFailedMessage, getResponseData, jsonRPC} from "@/utils/http_utils";
import {message} from "ant-design-vue";
import {isTrue} from "@/utils/common_utils";
import {useRouter} from 'vue-router';

const router = useRouter();

logDebug('TerminalService setup!')

const props = defineProps({
  primal: {type: String},
  data: {type: Object},
  styleSetting: {type: Object},
})
const contentHeight = ref(props.styleSetting.contentStyle.height)

const emit = defineEmits(['refresh']); //声明 emits

const primal = props.primal
const data = props.data

const envId = data['env_id']

const labelCol = {
  style: {
    width: '150px',
  },
};
const wrapperCol = {
  span: 14,
};

const createServiceForm = reactive({
  protocol: '',
  servicePort: '',
})

const openAddServiceModal = ref(false)
const handlePpenAddServiceModal = function () {
  openAddServiceModal.value = true
}

const addService = function () {
  logDebug(`addService, createServiceForm[${JSON.stringify(createServiceForm)}]`)

  const port = parseInt(`${createServiceForm.servicePort}`);
  const is_lb = `${createServiceForm.protocol}`.toUpperCase().endsWith("-LB")

  let protocol
  if (createServiceForm.protocol === "HTTP") {
    protocol = "HTTP"
  } else if (createServiceForm.protocol === "TCP") {
    protocol = "TCP"
  } else if (createServiceForm.protocol === "UDP") {
    protocol = "UDP"
  } else if (createServiceForm.protocol === "TCP-LB") {
    protocol = "TCP"
  } else if (createServiceForm.protocol === "UDP-LB") {
    protocol = "UDP"
  } else {
    protocol = "TCP"
  }

  const is_http = protocol === "HTTP";
  if (is_http) {
    protocol = "TCP"
  }

  const params = {
    target_port: port,
    env_id: envId,
    is_http: is_http,
    protocol: protocol,
    external_domain_list: '',
    is_lb: is_lb,
  }

  const addServiceResult = jsonRPC({
    url: "/api/experiment/service/add",
    params: params,
    success(res) {
      const data = getResponseData(res)
      logDebug('addServiceResult', data)
      loadService()
    },
    fail(error) {
      logError(`添加应用环境服务失败, `, error)
      message.error(`添加应用环境服务失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })

  return addServiceResult.then(function (res) {
    logDebug(`addServiceResult, `, res)
    loadService()
    emit("refresh", {}); //触发事件
  })
}

const handlePpenAddServiceModalOk = function () {
  addService()
  openAddServiceModal.value = false
}

const serviceColumns = ref([
  {
    title: 'ID',
    // dataIndex是JSON数据的字段名称
    dataIndex: 'id',
    // key是column的索引名称
    key: 'id',
  },
  {
    title: '服务类型',
    dataIndex: 'service_type',
    key: 'service_type',
  },
  {
    title: '内部端口',
    dataIndex: 'target_port',
    key: 'target_port',
  },
  {
    title: '外部端口',
    key: 'adjust_node_port',
    dataIndex: 'adjust_node_port',
  },
  {
    title: '访问地址',
    key: 'adjust_access_url',
    dataIndex: 'adjust_access_url',
  },
  {
    title: '操作',
    key: 'action',
  },
]);
const serviceData = ref([]);

const deleteService = function (record) {
  logDebug(`deleteService[${JSON.stringify(record)}]`)

  const portId = record['id']

  const deleteServiceResult = jsonRPC({
    url: "/api/experiment/service/delete",
    params: {
      port_id: portId,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug('getServiceResult', data)
      loadService()
    },
    fail(error) {
      logError(`删除指定应用环境服务失败, `, error)
      message.error(`删除指定应用环境服务失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })

  return deleteServiceResult.then(function (res) {
    logDebug(`deleteServiceResult, `, res)
    loadService()
    emit("refresh", {}); //触发事件
  })
}

onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`TerminalService onMounted.`)
})

const adjustServiceData = function (services) {
  for (const service of services) {
    if (isTrue(service['is_lb'])) {
      service['adjust_node_port'] = service['port']
    } else if (service['node_port'] === 0) {
      service['adjust_node_port'] = '无'
    } else {
      service['adjust_node_port'] = service['node_port']
    }

    if (isTrue(service['is_lb'])) {
      service['adjust_access_url'] = service['host']
    } else if (isTrue(service['http'])) {
      service['adjust_access_url'] = service['access_url']
    } else {
      service['adjust_access_url'] = service['host']
    }
  }
  return services
}

const loadService = function () {
  const getServiceResult = jsonRPC({
    url: "/api/experiment/services",
    params: {
      env_id: envId,
    },
    success(res) {
      const data = getResponseData(res)
      logDebug('getServiceResult', data)
      serviceData.value = adjustServiceData(data['services'])
    },
    fail(error) {
      logError(`获取服务列表失败, `, error)
      message.error(`获取服务列表失败，[${JSON.stringify(getFailedMessage(error))}]`, 3);
    },
  })

  return getServiceResult.then(function (res) {
    logDebug(`getServiceResult, `, res)
  })
}

const onSelected = function () {
  logDebug(`TerminalService onSelected. data[${JSON.stringify(data)}]`)

  loadService()
}

const activeTab = ref('service');
const networkInfo = ref({
  ipv4Address: '',
  ipv6Address: '',
  ipv4Gateway: '',
  ipv6Gateway: '',
  ipv4Dns1: '',
  ipv4Dns2: '',
  ipv6Dns: '',
});

const goToCloudLink = () => {
  router.push({
    name: 'desktop',
    params: {
      desktop_t: 'cloud_link'
    }
  });
};

defineExpose({
  primal,
  data,
  onSelected,
})

</script>

<style lang="scss" scoped>
.service {
  height: v-bind(contentHeight);
  overflow-y: auto;

  > div {
    padding: 20px;
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);

    .service-tabs {
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e8e8e8;
      padding-bottom: 12px;

      .tab-list {
        display: flex;
        gap: 20px;

        .tab-item {
          font-size: 16px;
          cursor: pointer;
          padding: 8px 16px;
          color: #666;
          position: relative;

          &.active {
            color: #1890ff;
            font-weight: bold;

            &::after {
              content: '';
              position: absolute;
              bottom: -13px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: #1890ff;
            }
          }
        }
      }

      .ant-btn-primary {
        background-color: #1890ff;
        border-color: #1890ff;
        border-radius: 4px;

        &:hover {
          background-color: #40a9ff;
          border-color: #40a9ff;
        }
      }
    }

    .service-table {
      margin: 20px 0;

      :deep(.ant-table-thead > tr > th) {
        background-color: #fafafa;
        font-weight: bold;
      }

      :deep(.ant-table-tbody > tr > td) {
        padding: 12px 8px;
      }
    }

    .service-tip {
      color: #666;
      margin-top: 15px;
      font-size: 14px;
    }
  }

  .network-content {
    .network-info {
      padding: 20px 0;

      .info-row {
        display: flex;
        margin-bottom: 24px;

        .info-item {
          flex: 1;
          display: flex;
          align-items: flex-start;

          .label {
            width: 150px;
            color: #333;
            font-weight: 500;
          }

          .value {
            color: #666;

            div {
              margin-bottom: 8px;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }

          .cloud-link {
            cursor: pointer;
            display: inline-flex;
            align-items: center;

            &:hover {
              opacity: 0.8;
            }
          }

          .wifi-icon {
            width: 24px;
            height: 24px;
            vertical-align: middle;
          }
        }
      }
    }
  }
}
</style>