/** 类似于 windows 的注册表，所有可用的 application & icon都在这里定义**/
const register = {
    "MAXTASK": 50,
    /**最大任务数 **/
    "titleHeight": 30,
    /**标题栏高度 see global.less !!!**/
    "taskHeight": 40,
    /**任务栏高度  see global.less !!!**/
    "application": [
        {
            "id": "0000",
            "name": "关于",
            "page": "sys/about",
            "link": false,
            "width": 800,
            "height": 400,
            "icon": "about",
            "resizable": false,
        },
        {
            "id": "9999",
            "name": "帮助",
            "page": "sys/help",
            "link": false,
            "width": 1024,
            "height": 600,
            "icon": "help",
            "resizable": true,
        },
        {
            "id": "0001",
            "name": "设置",
            "page": "sys/setting",
            "link": false,
            "width": 550,
            "height": 450,
            "icon": "system",
            "resizable": false
        },
        {
            "id": "0002",
            "name": "计算器",
            "page": "sys/calculator",
            "link": false,
            "width": 300,
            "height": 300,
            "icon": "calculator",
            "resizable": false
        },
        {
            "id": "0003",
            "name": "个人信息",
            "page": "sys/users",
            "link": false,
            "width": 1024,
            "height": 600,
            "icon": "users",
            "resizable": false, //显示右上角最大化最小化
        },
        {
            "id": "0004",
            "name": "云端互联",
            "page": "sys/cloud_link",
            "link": false,
            "width": 800,
            "height": 450,
            "icon": "cloud",
            "resizable": false
        },
        {
            "id": "0005",
            "name": "设置",
            "page": "sys/settingNew",
            "link": false,
            "width": 1024,
            "height": 600,
            "icon": "system",
            "resizable": false
        },
        // ************************** 下边是abc的页面 **************************************************************
        {
            "id": "8000",
            "name": "应用中心",
            "page": "abcApps",
            "icon": "abcApps",
            "width": 1024,
            "height": 600,
            "link": true,
            "abcPage": true,
        },
        {
            "id": "8001",
            "name": "我的应用",
            "page": "abcEnvironment",
            "icon": "abcEnvironment",
            "width": 1024,
            "height": 600,
            "link": true,
            "abcPage": true,
        },
        {
            "id": "8002",
            "name": "我的模板",
            "page": "abcTemplate",
            "icon": "abcTemplate",
            "width": 1024,
            "height": 600,
            "link": true,
            "abcPage": true,
        },
        {
            "id": "8003",
            "name": "空间管理",
            "page": "abcNamespace",
            "icon": "abcNamespace",
            "width": 1024,
            "height": 600,
            "link": true,
            "abcPage": true,
        },
        // {
        //     "id": "8004",
        //     "name": "任务管理器",
        //     "page": "abcTaskManager",
        //     "icon": "TaskManager",
        //     "width": 1024,
        //     "height": 600,
        //     "link": true,
        //     "abcPage": true,
        // },
        {
            "id": "8005",
            "name": "课程学习",
            "page": "abcCourse",
            "icon": "abcCourse",
            "width": 1024,
            "height": 600,
            "link": true,
            "abcPage": true,
        },
        {
            "id": "8006",
            "name": "直播实训",
            "page": "abcLive",
            "icon": "abcLive",
            "width": 1024,
            "height": 600,
            "link": true,
            "abcPage": true,
        },
        // {
        //     "id": "8007",
        //     "name": "项目研发",
        //     "page": "abcProject",
        //     "icon": "abcProject",
        //     "width": 1024,
        //     "height": 600,
        //     "link": true,
        //     "abcPage": true,
        // },
        // {
        //     "id": "8008",
        //     "name": "教务管理",
        //     "page": "abcEducational",
        //     "icon": "abcEducational",
        //     "width": 1024,
        //     "height": 600,
        //     "link": true,
        //     "abcPage": true,
        // },
        {
            "id": "8009",
            "name": "求职就业",
            "page": "abcJob",
            "icon": "abcJob",
            "width": 1024,
            "height": 600,
            "link": true,
            "abcPage": true,
        },
        {
            "id": "8010",
            "name": "我的班级",
            "page": "abcClass",
            "icon": "abcClass",
            "width": 1024,
            "height": 600,
            "link": true,
            "abcPage": true,
        },
        // {
        //     "id": "8011",
        //     "name": "帮助教程",
        //     "page": "abcHelpTutorial",
        //     "icon": "abcHelpTutorial",
        //     "width": 1024,
        //     "height": 600,
        //     "link": true,
        //     "abcPage": true,
        // },
        {
            "id": "8012",
            "name": "课程制作",
            "page": "abcCourseMake",
            "icon": "abcCourseMake",
            "width": 1024,
            "height": 600,
            "link": true,
            "abcPage": true,
        },
        {
            "id": "8013",
            "name": "我的镜像",
            "page": "abcImage",
            "icon": "abcImage",
            "width": 1024,
            "height": 600,
            "link": true,
            "abcPage": true,
        },
        {
            "id": "8014",
            "name": "购物车",
            "page": "abcShop",
            "icon": "abcShop",
            "width": 1024,
            "height": 600,
            "link": true,
            "abcPage": true,
        },
        {
            "id": "8015",
            "name": "教务管理",
            "page": "abcEduAdmin",
            "icon": "abcEduAdmin",
            "width": 1024,
            "height": 600,
            "link": true,
            "abcPage": true,
        },
        {
            "id": "8016",
            "name": "存储管理",
            "page": "abcStorageBucket",
            "icon": "abcStorageBucket",
            "width": 1024,
            "height": 600,
            "link": true,
            "abcPage": true,
        },
        {
            "id": "8017",
            "name": "博客",
            "page": "abcBlog",
            "icon": "abcBlog",
            "width": 1024,
            "height": 600,
            "link": true,
            "abcPage": true,
        },
        {
            "id": "8018",
            "name": "论坛",
            "page": "abcForum",
            "icon": "abcForum",
            "width": 1024,
            "height": 600,
            "link": true,
            "abcPage": true,
        },
    ]
}

export default register
