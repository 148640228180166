<template>
  <div class="container">
    <div class="stars">
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
      <div class="star"></div>
    </div>
    <a-card class="main-card">
      <div class="header">
        <img src="@/assets/icon/bucket.png" alt="bucket" class="header-icon"/>
        <h2 class="title">文件转存</h2>
      </div>
      
      <div class="file-list">
        <div v-for="record in sourceFile_list.records" :key="record" class="file-item">
          <div class="file-info">
            <span>分享人: {{ record.user_name }}</span>
            <a-divider type="vertical" />
            <span>访问次数: {{ record.access_count }}</span>
            <a-divider type="vertical" />
            <span>过期时间: {{ record.share_type === 'permanent' ? '长期有效' : formatExpirationDate(record.expiration_date) }}</span>
          </div>
        </div>
        <a-card v-for="item in sourceFile_list.file_data" :key="item" class="file-item">
          <div class="file-content">
            <div class="file-details">
              <p>文件名称: {{ item.file_name }}</p>
              <p>文件大小: {{ formatFileSize(item.file_size) }}</p>
            </div>
            <a-button type="primary" @click="copyBucketModel(item)">转存</a-button>
          </div>
        </a-card>
      </div>

      <!-- 转存对话框 -->
      <a-modal :open="openCopyBucketListModel"
               @cancel="openCopyBucketListModel=false"
               :mask="false"
               width="60%"
               @ok="copyBucket">
        <a-spin :spinning="targetBucket_list.loading" tip="正在执行转存...">
          <template #footer>
            <a-button shape="round" :loading="targetBucket_list.loading" key="back" @click="openCopyBucketListModel=false">取消</a-button>
            <a-button shape="round" :loading="targetBucket_list.loading" key="submit" type="primary" @click="copyBucket">转存到此</a-button>
          </template>
          
          <h4>选择目标位置</h4>
          <a-card :tab-list="tabListNoTitle" :active-tab-key="noTitleKey" @tabChange="key => onTabChange(key, 'noTitleKey')">
            <!-- 面包屑导航 -->
            <a-breadcrumb separator=">">
              <a-breadcrumb-item v-if="computedBucketPaths.length===0">
                <div class="ellipsis" :title="record">{{ selectBucketName.split('-').pop() }}</div>
              </a-breadcrumb-item>
              <template v-else>
                <a-breadcrumb-item @click="query_bucket_detail(selectBucketName)">
                                  <span style="cursor: pointer;" class="ellipsis"
                                        @click="query_bucket_detail(selectBucketName)">{{
                                      selectBucketName.split('-').pop()
                                    }}</span>
                </a-breadcrumb-item>
                <a-breadcrumb-item v-for="(item, index) in computedBucketPaths" :key="item">
                                  <span v-if="index !== computedBucketPaths.length - 1" style="cursor: pointer;"
                                        @click="query_bucket_detail(selectBucketName, item.path)">{{ item.name }}</span>
                  <span v-else>{{ item.name }}</span>
                </a-breadcrumb-item>
              </template>
            </a-breadcrumb>
            
            <!-- 文件夹列表 -->
            <div style="width: 100%;height: 100%;">
              <a-list v-if="targetBucket_list.records.length > 0" :data-source="targetBucket_list.records">
                <template #renderItem="{ item }">
                  <a-list-item>
                    <a-list-item-meta>
                      <template #title>
                        <a v-if="item.file_type && item.file_type == 'folder'"
                           @click="query_bucket_detail(item.user_bucket_name,item.file_key)"
                           :title="item.file_key">
                          <img v-if="item.file_type == 'folder'" src="@/assets/icon/folder.png">
                          <img v-else src="/hw_frontend/static/img/BucketFile.png">
                          {{ formatText(item.file_name) }}
                        </a>
                      </template>
                    </a-list-item-meta>
                  </a-list-item>
                </template>
              </a-list>
              <a v-else>
                <a-flex vertical align="center" justify="center">
                  <h3>保存到此处</h3>
                </a-flex>
              </a>
            </div>
          </a-card>
        </a-spin>
      </a-modal>
    </a-card>
  </div>
</template>

<script setup>
import {computed, onMounted, reactive, ref} from 'vue'
import {useRoute} from 'vue-router'
import {logDebug, logError} from "@/utils/logger";
import {getResponseData, jsonRPC} from "@/utils/http_utils";
import {message} from "ant-design-vue";

const route = useRoute()

function formatText(input) {
  // 检查字符串是否以 '/' 结尾，如果是则去掉
  if (typeof input === "string" && input.endsWith("/")) {
    return input.slice(0, -1);
  }
  return input;
}

// 通过 `useRoute` 获取动态参数和查询参数
const id = route.params.id
const fid = route.query.fid
const exp = route.query.exp

const tabListNoTitle = ref([]);
const key = ref('');
const bucketName = ref('');
const noTitleKey = ref('');
const selectBucketName = ref('');
const onTabChange = (value, type) => {
  selectBucketName.value = value;
  query_bucket_detail(value, '')
  if (type === 'key') {
    key.value = value;
  } else if (type === 'noTitleKey') {
    noTitleKey.value = value;
  }
};
const computedBucketPaths = computed(() => {
  // 如果路径为空或无效，返回空数组
  if (!current_file_path.value || current_file_path.value.trim() === '') {
    return [];
  }

  // 去掉路径末尾的 `/`，然后按 `/` 分割路径
  const modifiedStr = current_file_path.value.endsWith('/') ? current_file_path.value.slice(0, -1) : current_file_path.value;
  const parts = modifiedStr.split('/');

  // 如果路径部分小于 1，返回空数组
  if (parts.length < 1) {
    return [];
  }

  const result = [];
  let prevPath = '';

  // 遍历路径部分并累加
  for (let i = 0; i < parts.length; i++) {
    const currentPart = parts[i];
    prevPath += currentPart + '/';

    // 添加当前路径部分的对象
    result.push({
      name: currentPart,
      path: prevPath
    });
  }

  return result;
});

const targetBucket_list = reactive({
  bucket_list: [],
  records: [],
  loading: false,
  used_sizes: 0,
  available_sizes: 0,
})
const sourceFile_list = reactive({
  records: [],
  file_data: [],
  loading: false,
})
const current_file = ref(null)

const target_file_path = ref('')  // 目标文件路径
const bucket_file_key_list = ref([])  // 转存文件key
const openCopyBucketListModel = ref(false)

const current_file_path = ref('');
const query_bucket_detail = (e = '', path = '') => {
  current_file_path.value = path;
  selectBucketName.value = e
  nextBucketContent();
};
const nextBucketContent = () => {
  logDebug("nextBucketContent按照前缀查询文件夹")
  jsonRPC({
    url: "/api/query/user/oss/bucket/detail",
    params: {
      'user_bucket_name': selectBucketName.value,
      'file_path': current_file_path.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`nextBucketContent按照前缀查询文件夹`, data);
      if (data.all_list.length > 0) {
        targetBucket_list.records = data.all_list.filter(item => item.file_type === 'folder')
      } else {
        targetBucket_list.records = []
      }
      logDebug(targetBucket_list.records)
    },
    fail(error) {
      logError(`加载数桶据失败`, error);
      message.error(error, 3);
    },
  })
}
// 复制
const copyBucketModel = (record) => {
  logDebug("copyBucketModel")
  current_file.value = record
  bucketName.value = record.user_bucket_name

  copyTargetBucket(current_file.value)
}
const copyTargetBucket = (record) => {
  // 复制到至目标存储桶
  logDebug(record)
  targetBucket_list.loading = true
  jsonRPC({
    url: "/api/query/user/bucket/list",
    success(res) {
      const data = getResponseData(res);
      logDebug("复制到目标存储桶")
      logDebug(data)

      targetBucket_list.bucket_list = data.user_id_bucket_list;
      tabListNoTitle.value = []
      if (data.user_id_bucket_list.length > 0) {
        for (let i = 0; i < data.user_id_bucket_list.length; i++) {
          let current_data = data.user_id_bucket_list[i]
          // 添加当前路径部分的对象
          tabListNoTitle.value.push({
            key: current_data.bucket_name,
            tab: current_data.bucket_custom_name,
          });
        }
        noTitleKey.value = tabListNoTitle.value[0].key
        query_bucket_detail(tabListNoTitle.value[0].key, '')
      }

      logDebug(targetBucket_list.bucket_list)
      targetBucket_list.used_sizes = data.used_sizes;
      targetBucket_list.available_sizes = data.available_sizes;
    },
    fail(error) {
      logError(`获取自定义桶数据失败`, error);
    },
  }).then(() => {
    targetBucket_list.loading = false
    openCopyBucketListModel.value = true
  });
}
const copyBucket = () => {
  if (!current_file.value) {
    message.error('请选择要转存的文件');
    return;
  }
  
  // 转存至另一个存储桶
  target_file_path.value = current_file_path.value
  bucket_file_key_list.value = [current_file.value.file_key]
  targetBucket_list.loading = true

  jsonRPC({
    url: "/api/oss/bucket/copy/other_bucket",
    params: {
      'bucket_name': bucketName.value,
      'bucket_file_key_list': bucket_file_key_list.value,
      'target_bucket_name': selectBucketName.value,
      'target_file_path': target_file_path.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`转存成功`, data);
      message.success(`转存成功`, 2)
      bucket_file_key_list.value = []
    },
    fail(error) {
      message.error(`转存失败,请稍后再试！`, 2)
      logError(`转存失败`, error);
      bucket_file_key_list.value = []
    },
  }).then(() => {
    openCopyBucketListModel.value = false
    targetBucket_list.loading = false
  })
}

function fetchData() {
  sourceFile_list.loading = true
  jsonRPC({
    url: "/api/oss/share/link/details",
    params: {
      'id': id,
      'fid': fid,
      'exp': exp,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`加载数桶据成功`, data);
      sourceFile_list.records = data.records;
      sourceFile_list.file_data = data.file_data_records;
    },
    fail(error) {
      logDebug(error)
      logError(`加载数桶据失败`, error);
      message.error(error, 3);
    },
  }).then(() => {
    sourceFile_list.loading = false;
  });
}

// 添加文件大小格式化函数
const formatFileSize = (bytes) => {
  if (!bytes || bytes === 0) return '0 B';
  
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  const k = 1024;
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  
  return `${(bytes / Math.pow(k, i)).toFixed(2)} ${units[i]}`;
}

function formatExpirationDate(date) {
  // 实现日期格式化逻辑
  return date; // 临时返回，需要根据实际需求实现
}

onMounted(() => {
  // 在onMounted中获取数据
  logDebug(`BucketCopy onMounted. id[${id}]`);
  fetchData()
});
</script>

<style scoped lang="scss">
@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 100% !important;
  }
}

@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 100% !important;
  }
}

@media (min-width: 768px) {
  .container, .container-md, .container-sm {
    max-width: 100% !important;
  }
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 100% !important;
  }
}

.container {
  position: fixed;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  top: 0;
  left: 0;
  background: radial-gradient(ellipse at bottom, #1b2947 0%, #75517d 40%, #e96f92 65%, #f7f7b6 100%);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.stars {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  transform: rotate(-45deg);
  z-index: 0;
}

.star {
  --star-color: var(--primary-color);
  --star-tail-length: 6em;
  --star-tail-height: 2px;
  --star-width: calc(var(--star-tail-length) / 6);
  --fall-duration: 9s;
  --tail-fade-duration: var(--fall-duration);
  position: absolute;
  top: var(--top-offset);
  left: 0;
  width: var(--star-tail-length);
  height: var(--star-tail-height);
  color: var(--star-color);
  background: linear-gradient(45deg, currentColor, transparent);
  border-radius: 50%;
  filter: drop-shadow(0 0 6px currentColor);
  transform: translate3d(104em, 0, 0);
  animation: fall var(--fall-duration) var(--fall-delay) linear infinite, tail-fade var(--tail-fade-duration) var(--fall-delay) ease-out infinite;
}

@keyframes fall {
  to {
    transform: translate3d(-30em, 0, 0);
  }
}

@keyframes tail-fade {
  0%, 50% {
    width: var(--star-tail-length);
    opacity: 1;
  }
  70%, 80% {
    width: 0;
    opacity: 0.4;
  }
  100% {
    width: 0;
    opacity: 0;
  }
}

.star:nth-child(1) { --star-color: #fff; --fall-duration: 11.5s; --fall-delay: 9.8s; --top-offset: 37vh; }
.star:nth-child(2) { --star-color: #fff; --fall-duration: 12.7s; --fall-delay: 0.2s; --top-offset: 62vh; }
.star:nth-child(3) { --star-color: #fff; --fall-duration: 10.6s; --fall-delay: 3.1s; --top-offset: 84vh; }
.star:nth-child(4) { --star-color: #fff; --fall-duration: 11.9s; --fall-delay: 5.4s; --top-offset: 28vh; }
.star:nth-child(5) { --star-color: #fff; --fall-duration: 10.2s; --fall-delay: 7.7s; --top-offset: 96vh; }

.main-card {
  width: 90%;
  margin: auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(10px);
  z-index: 1;
  max-height: 90vh;
  overflow-y: auto;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  &::-webkit-scrollbar {
    width: 6px;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
  }
  
  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
  }
  
  :deep(.ant-card-body) {
    padding: 24px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2%;
  
  .header-icon {
    width: 32px;
    height: 32px;
    margin-right: 12px;
  }
  
  .title {
    margin: 0;
    color: #1890ff;
    font-size: 24px;
    font-weight: 500;
  }
}

.file-list {
  .file-item {
    margin-bottom: 16px;
    transition: all 0.3s ease;
    border-radius: 8px;
    overflow: hidden;
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
    }
  }
}

.file-info {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  background-color: #fafafa;
  border-radius: 8px;
  
  span {
    color: #666;
    font-size: 14px;
    white-space: nowrap;
  }
}

.file-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 8px;
  
  .file-details {
    p {
      margin-bottom: 8px;
      color: #333;
      font-size: 14px;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  .ant-btn {
    min-width: 80px;
    border-radius: 4px;
    
    &:hover {
      transform: scale(1.02);
    }
  }
}

// 添加响应式设计
@media (max-width: 768px) {
  .main-card {
    width: 95%;
  }
  
  .header {
    .title {
      font-size: 20px;
    }
  }
  
  .file-info {
    flex-direction: column;
    align-items: flex-start;
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>