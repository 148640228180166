<template>
  <div class="training">
    <swiper v-if="banners.length > 0"
      :modules="[Pagination, Autoplay, Keyboard, EffectFade]"
      :slides-per-view="1"
      :space-between="0"
      :loop="true"
      :effect="'fade'"
      :keyboard="{ enabled: true }"
      :pagination="{ clickable: true, renderBullet: bulletRenderer }"
      :autoplay="{delay: 2500,pauseOnMouseEnter: true}"
    >
      <!-- 加载Swiper的Pagination(分页)和Autoplay(自动播放)模块 -->
      <!-- 设置每次显示一个滑块 -->
      <!-- 滑块间距为0 -->
      <!-- 启用循环模式，滑动到最后一个时会循环到第一个 -->
      <!-- 启用键盘控制滑动功能 -->
      <!-- 启用可点击的分页，并使用自定义bulletRenderer -->
      <!-- 自动播放，每隔2500ms切换一次，且用户交互后不会停止自动播放 -->
      <swiper-slide v-for="(row, index) in banners" :key="index">
        <router-link :to="`/training/${row}`" class="img_box_banner">
          <img :src="`/web/image/hw.certification.template/${row}/banner`" />
        </router-link>
      </swiper-slide>
    </swiper>
    <div class="container training_page">
      <div class="search">
        <a-input-search placeholder="请输入名称" enter-button allowClear @search="onSearch"/>
      </div>
      <div class="list">
        <div class="row mx-0 border-bottom" v-for="(row, rowIndex) in data_list" :key="rowIndex">
          <div class="col-md-3 pl-0">
            <div class="img_box">
              <img :src="`/web/image/hw.certification.template/${row.id}/image_512?t=${new Date().getTime()}`"/>
            </div>
          </div>
          <div class="col-md-6 info px-0">
            <h5>
              <router-link :to="`/training/${row.id}`">{{ row.name }}</router-link>
            </h5>
            <div v-if="row.training_type === '0'">
              <div class="course">
                必修课程：
                <span v-for="course in row.course_ids" :key="course.id">
                  {{ course.name }}
                </span>
              </div>
              <div style="color: #9D9D9D;">
                课时数：{{ row.period_num }}
              </div>
              <a-flex wrap="wrap" style="column-gap: 18px;" v-if="row.class_id">
                <div>本期学习时间：{{row.class_opening_date}} ~ {{row.class_ending_date}}</div>
                <div>本期考试时间：{{row.exam_start_time}} ~ {{row.exam_end_time}}</div>
              </a-flex>
            </div>
            <div>
              <template v-if="row.student_ids.includes(user)">
                <router-link v-if="row.training_type === '0'" :to="`/training/${row.id}`" class="btn">
                  继续学习
                </router-link>
                <template v-if="complete[rowIndex] && complete[rowIndex].is_progress">
                  <template v-if="!complete[rowIndex].certification ||(complete[rowIndex].certification.state !== '1' &&complete[rowIndex].certification.frequency !== 0)">
                    <a v-if="complete[rowIndex].is_notice &&(!complete[rowIndex].confirmation_notice ||!complete[rowIndex].confirmation_notice.is_confirm)"
                      class="btn join" @click.prevent="alertAndRedirect(row.id)">开始考试</a>
                    <a v-else-if="row.can_exam" @click="onExam(row.exam_page_id.id,row.id)" class="btn join">开始考试</a>
                    <a v-else class="btn join disabled">开始考试</a>
                  </template>
                </template>
                <router-link v-if="row.has_exercise" :to="`/training/${row.id}?activeKey=4`" class="btn">
                  刷题训练
                </router-link>
              </template>
              <a v-else @click="onJoin(row.id)" class="btn join">
                加入培训
              </a>
            </div>
          </div>
          <div class="col-md-3 px-0 text-right">
            <template v-if="row.student_ids.includes(user)">
              <template v-if="complete[rowIndex]">
                <template v-if="complete[rowIndex].is_progress">
                  <p v-if="row.training_type === '0'">已完成必修课程学习</p>
                  <!-- <p v-else>已完成线下课程学习</p> -->
                  <template v-if="complete[rowIndex].certification">
                    <p v-if="complete[rowIndex].certification.state === '1'">
                      考试通过
                    </p>
                    <template v-else>
                      <p class="undone">考试未通过</p>
                      剩余考试次数：
                      <span>{{complete[rowIndex].certification.frequency}}次</span>
                    </template>
                  </template>
                </template>
                <p v-else class="undone">未完成必修课程学习</p>
              </template>
            </template>
          </div>
        </div>
      </div>
    </div>
    <a-flex justify="center" class="pb-3">
      <a-pagination
        v-model:current="current"
        :showSizeChanger="showSizeChanger"
        v-model:page-size="pageSize"
        :total="total"
        :locale="zhCn"
        :pageSizeOptions="pageSizeOptions"
        :hideOnSinglePage="true"
      />
    </a-flex>
  </div>
</template>

<script setup>
import { logDebug, logError } from "@/utils/logger";
import { message, Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import { zhCn } from "@/utils/zhCn";
import { onMounted, ref, watch, createVNode } from "vue";
import { jsonRPC, getResponseData } from "@/utils/http_utils";
import { useRouter } from "vue-router";
const router = useRouter();
import { Pagination, Autoplay, Keyboard, EffectFade } from "swiper/modules";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/effect-fade";
const bulletRenderer = (index, className) => {
  const _className = `${className} swiper-pagination-bullet-custom`;
  return `<span class="${_className}">${index + 1}</span>`;
};

const banners = ref([]);
const data_list = ref([]);
const total = ref(0);
const showSizeChanger = ref(true);
const pageSize = ref(4);
const current = ref(1);
const search = ref("");
const pageSizeOptions = ref(
  Array.from({ length: 4 }, (_, index) =>
    (pageSize.value * (index + 1)).toString()
  )
);
const user = ref(); // 当前用户
const complete = ref([]); // 完成状态
function fetchData() {
  jsonRPC({
    url: "/api/training",
    params: {
      page_index: current.value,
      page_size: pageSize.value,
      search: search.value,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`获取training数据成功`, data);
      banners.value = data.banners;
      data_list.value = data.records;
      total.value = data.record_count;
      user.value = data.user;
      complete.value = data.complete;
    },
    fail(error) {
      logError(`获取失败`, error);
      message.error(`获取证书失败，请稍后再试`);
    },
  });
}
watch([pageSize, current], fetchData);
onMounted(() => {
  fetchData();
});
const onSearch = (searchValue) => {
  if (search.value != searchValue) {
    current.value = 1;
    search.value = searchValue;
    fetchData();
  }
};
const onJoin = (e)=>{
  jsonRPC({
    url: "/api/training/join",
    params: {
      id: e,
    },
    success(res) {
      const data = getResponseData(res);
      logDebug(`加入培训成功`, data);
      message.success(`加入培训成功`);
      fetchData();
    },
    fail(error) {
      logError(`加入培训失败`, error);
      message.error(`加入培训失败，请稍后再试`);
    },
  });
}
function alertAndRedirect(e) {
  Modal.confirm({
    title: '请先阅读学员须知！',
    icon: createVNode(ExclamationCircleOutlined),
    onOk() {
      router.push(`/training/${e}?activeKey=2`)
    },
  });
}
// 开始考试
const onExam = (exam_page_id,e)=>{
  router.push(`/training/${e}/${exam_page_id}`)
}
</script>

<style scoped lang="scss">
.training {
  background-color: #fff;
  // 按比例封装图片
  .img_box_banner {
    display: block;
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 14%;
    > img {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: cover; /* 图像保持自身比例填满容器，并裁剪掉超出的部分 */
    }
  }
  // 轮播分页样式
  --swiper-pagination-bottom: 16px;
  :deep(.swiper-pagination-bullet-custom) {
    width: 20px !important;
    height: 20px !important;
    line-height: 20px !important;
    text-align: center;
    background-color: #f8f8f8;
    color: #24292f94;
    transition: all 0.2s;
    opacity: 0.6;
    &:hover {
      opacity: 1;
      color: #24292f;
    }
    &.swiper-pagination-bullet-active {
      opacity: 1;
      color: #fff;
      background-color: #007aff;
    }
  }
  .training_page {
    padding-bottom: 1.5rem;
    .search {
      display: flex;
      justify-content: flex-end;
      padding: 1rem 0;
    }
    > .list {
      > div {
        padding-top: 20px;
        padding-bottom: 20px;
        > div:first-child {
          padding-right: 20px;
          > .img_box {
            padding-bottom: 62.5%;
          }
        }
        > .info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          > h5 {
            margin: 0;
            a {
              font-size: 16px;
              font-weight: bold;
              color: #000;
            }
          }
          .course {
            > span {
              color: #57a6ff;
              margin-right: 7px;
              white-space: nowrap;
            }
          }
          > div:last-child {
            font-size: 0;
            > .btn {
              padding-top: 0;
              padding-bottom: 0;
              width: 100px;
              line-height: 28px;
              text-align: center;
              color: #fff;
              background-color: #57a6ff;
              margin-right: 10px;
              font-size: 16px;
              &.join {
                background-color: #ff8857;
              }
              &:hover {
                opacity: 0.8;
              }
              &.disabled {
                background-color: #ccc;
                cursor: not-allowed;
              }
            }
          }
        }
        > div:last-child {
          font-size: 16px;
          > p,
          span {
            color: #57a6ff;
            margin: 10px 0;
            font-size: 16px;
          }
          > .undone {
            color: #ff8857;
          }
        }
      }
    }
  }
}
</style>
